<template>
    <div>
        <Navbar />
        <div class="mx-5 mt-5">
            <template>
                <b-container fluid>
                    <b-row align-h="between">
                        <b-col lg="6" class="my-1">
                            <b-form-group label="Sort" label-for="sort-by-select" label-cols-sm="3" label-align-sm="right"
                                label-size="sm" class="mb-0" v-slot="{ ariaDescribedby }">
                                <b-input-group size="sm">
                                    <b-form-select id="sort-by-select" v-model="sortBy" :options="sortOptions"
                                        :aria-describedby="ariaDescribedby" class="w-75">
                                        <template #first>
                                            <option value="">-- none --</option>
                                        </template>
                                    </b-form-select>

                                    <b-form-select v-model="sortDesc" :disabled="!sortBy"
                                        :aria-describedby="ariaDescribedby" size="sm" class="w-25">
                                        <option :value="false">Asc</option>
                                        <option :value="true">Desc</option>
                                    </b-form-select>
                                </b-input-group>
                            </b-form-group>
                        </b-col>

                        <b-col cols="12" sm="12" md="6" lg="4" class="my-1">
                            <b-form-group label-for="filter-input" label-cols-sm="3" label-align-sm="right" label-size="sm"
                                class="mb-0">
                                <b-input-group size="sm">
                                    <b-form-input id="filter-input" v-model="filter" type="search"
                                        placeholder="Buscar paciente" />
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <!-- Main table element -->
                    <b-table class="mb-4 mt-4" :items="pacientes" :fields="fields" :current-page="currentPage"
                        :per-page="perPage" :filter="filter" :filter-included-fields="filterOn" :sort-by.sync="sortBy"
                        show-empty hover small @filtered="onFiltered" :thead-class="'th_style'"
                        :tbody-class="'border_table'">
                        <template #cell(name)="row">
                            {{ row.value.first }} {{ row.value.last }}
                        </template>

                        <template #cell(actions)="row">
                            <a @click="toNotas(row.item)">
                                <i class="fa fa-arrow-right action-icons"></i>
                            </a>
                        </template>

                        <template #row-details="row">
                            <b-card>
                                <ul>
                                    <li v-for="(value, key) in row.item" :key="key">{{ key }}: {{ value }}</li>
                                </ul>
                            </b-card>
                        </template>

                        <template #empty>
                            <div class="text-center">
                                <p>Cargando, por favor espere...</p>
                            </div>
                        </template>
                    </b-table>
                    <b-row class="justify-content-center">
                        <b-col cols="12" sm="12" md="4" lg="4" class="my-1">
                            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill"
                                pills size="sm" class="my-0  mi-color-personalizado">
                            </b-pagination>
                        </b-col>
                    </b-row>
                </b-container>
            </template>
        </div>
    </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import { getCatalogo } from "../../helpers/catalogos"
import router from "../../router";
export default {
    name: 'Listado',
    components: { Navbar },
    data: () => ({
        pacientes: [],
        fields: [
            { key: 'number', label: 'Número', class: 'text-center' },
            { key: 'nombre', label: 'Nombre del paciente',sortable: true },
            { key: 'no_expediente', label: 'Número de expediente', class: 'text-center',sortable: true },
            {
                key: 'isActive',
                label: 'Estatus',
                formatter: (value) => {
                    return value ? 'Yes' : 'No'
                },
                class: 'text-center',
                sortByFormatted: true,
                filterByFormatted: true
            },
            { key: 'actions', label: 'Acciones', class: 'text-center' }
        ],
        totalRows: 1,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        perPage: 10,
        filter: null,
        filterOn: [],
    }),

    computed: {
        sortOptions() {
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return { text: f.label, value: f.key }
                })
        }
    },

    mounted() {
        this.getPacientes()
    },

    methods: {
        resetInfoModal() {
            this.infoModal.title = ''
            this.infoModal.content = ''
        },

        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },

        getPacientes() {
            new Promise((resolve) => {
                resolve(getCatalogo('/api/pacientes'))
            }).then((data) => {
                try {
                    let pacientes = data.pacientes
                    const newArray = pacientes.map((item, index) => {
                        return {
                            number: index + 1,
                            ...item
                        }
                    })
                    this.pacientes = newArray
                    this.totalRows = data.pacientes.length
                } catch (error) {
                    console.log(error);
                }
            })
        },

        toNotas(paciente) {
            console.log(paciente);
            router.push({ name: 'registroHemodinamia', params: { id: paciente.id, idExp: paciente.lastExpediente.expediente_id } })
            // router.push('/enfermeria/registro/hemodinamia/'+paciente.id+'/expediente/'+paciente.lastExpediente.expediente_id)
        }
    }
}
</script>